.button {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    vertical-align: top;
    border: none;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.5;
    padding: 1.2rem 4.5rem;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
    color: #fff;
    border-radius: 0.4rem;
    background-color: #d33810;
    font-family: "Poppins", Arial, "Helvetica Neue", Helvetica, sans-serif;

    &:hover {
        color: #fff;
        background-color: #89352c;
    }
}

@media (max-width: 1023px) {
    .button {
        display: block;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
}