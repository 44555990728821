.review {
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: .4rem;
    padding: 4rem;
    transition: border-color .3s;

    &:hover {
        border-color: rgba(169, 75, 65, 0.28);
    }
}

.head {
    display: flex;
    align-items: center;
}

.logo {
    width: 4.8rem;
    height: 4.8rem;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    

    + .info {
        margin-left: 1.6rem;
    }
}

.info {
    font-size: 1.4rem;
    line-height: 1.4;
    color: #5C5858;

    p {
        margin: 0;
    }
}

.body {
    margin-top: 1.6rem;
    padding-top: 1.6rem;
    border-top: 1px solid #E6E6E6;
}

.content {
    p {
        margin: 0;

        + p {
            margin-top: 1.5rem;
        }
    }
}

@media (max-width: 1023px) {
    .review {
        padding: 2.4rem;
    }
}

@media (max-width: 767px) {
    .review {
        padding: 1.6rem;
    }
}