.grid {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 1023px) {
        grid-template-columns: 1fr;
    }
}

.title {
    display: block;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.4;
}

.list {
    padding: 0;
    margin: 1.6rem 0 0 0;
    list-style: none;

    @media (max-width: 1023px) {
        display: grid;
        grid-gap: 1.6rem;
        grid-template-columns: 1fr 1fr;
    }
}

.line {
    + .line {
        margin-top: 1.2rem;
        @media (max-width: 1023px) {
            margin-top: 0;
        }
    }
}

.box {
    background-color: #fff;
    padding: .8rem 1.6rem;
    display: flex;
    align-items: center;
    border-radius: .4rem;
}

.icon {
    width: 3.2rem;
    height: 3.2rem;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 1.6rem;
}

.text {
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: 600;
}