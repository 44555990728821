.button {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    vertical-align: top;
    border: none;
    background-color: transparent;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 1.1rem 2.1rem;
    transition: background-color .3s;
    color: #fff;
    border: 0.1rem solid #fff;
    border-radius: 0.4rem;
    background-color: transparent;
    font-family: "Poppins", Arial, "Helvetica Neue", Helvetica, sans-serif;

    &:hover {
        color: #fff;
        background: rgba(255, 255, 255, .12);
    }
}

.animation {
    animation: backgroundColor 5s infinite;
}

@keyframes backgroundColor {
    0% {
        background-color: transparent;
    }
    50% {
        background: rgba(255, 255, 255, .12);
    }
    100% {
        background-color: transparent;
    }
}