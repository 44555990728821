.section {
    padding: 8rem 0;
    background-color: #fff;
}

.content {
    max-width: 82.4rem;
    color: #0A0A0A;

    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.8;


    p {
        margin: 0;

        + p {
            margin-top: 1.6rem;
        }
    }

    h3 {
        margin-bottom: 1.2rem;
        margin-top: 5.6rem;

        &:first-child {
            margin-top: 0;
        }
    }

    h4 {
        margin-bottom: .8rem;
        margin-top: 2.4rem;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        
        li {
            padding-left: 3rem;
            position: relative;

            &:before {
                content:"";
                display: block;
                width: .5rem;
                height: .5rem;
                border-radius: 50%;
                background-color: #0A0A0A;
                position: absolute;
                top: 0;
                left: 0;
                margin: 1.3rem 0 0 1.25rem;
            }
        }
    }

    figure {
        margin: 6.4rem 0 0 0;
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        border: .4rem;
    }
    
}

@media (max-width: 1023px) {
    .content {
        font-size: 1.6rem;
        line-height: 1.5;

        h3 {
            margin-bottom: .8rem;
            margin-top: 3.2rem;
        }

        figure {
            margin-top: 3.2rem;
        }

        ul {
            
            li {
                padding-left: 3rem;
                position: relative;
    
                &:before {
                    margin-top: .8rem;
                }
            }
        }
    }

    .section {
        padding: 6.4rem 0;
    }
}