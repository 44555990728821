.welcome {
    position: relative;
    background: linear-gradient(101.73deg, #4c5061 1.46%, #272a3a 92.4%), linear-gradient(106.92deg, rgba(0, 0, 0, .64) 9.21%, rgba(52, 82, 82, .64));

    &.show {
        .image {
            opacity: 1;
        }

        .title,
        .description {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.inner {
    position: relative;
    z-index: 2;
    height: 100%;
    min-height: 80rem;
    display: flex;
    padding-top: 16rem;
    padding-bottom: 8rem;
    align-items: center;
    color: #fff;
}

.media {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    animation: scaleAnimation 12s infinite;
    opacity: 0;
    transition: opacity 2s;
    transition-delay: .1s;

    &:before {
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        background: linear-gradient(29deg, rgba(29, 40, 44, 0.8) 18.66%, rgba(89, 105, 105, 0) 76.01%), linear-gradient(0deg, rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.56));
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }
}

.button {
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-weight: 600;
    line-height: 1;
    display: inline-flex;
    align-items: center;

    svg {
        fill: #fff;
        width: 2.4rem;
        height: 2.4rem;
        display: block;
    }
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}

.panel {
    width: 70%;
    z-index: 5;

    @media (max-width: 1023px) {
        width: 100%;
        text-align: center;
        max-width: 60rem;
        margin: 0 auto;
    }

    @media (max-width: 767px) {
        text-align: left;
    }
}

.description {
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 1.6rem;
    font-weight: 400;

    @media (max-width: 767px) {
        font-size: 1.8rem;
    }
}

.description,
.title {
    opacity: 0;
    transform: translateY(3rem);
    transition: opacity .8s, transform .4s;
}

.description {
    transition-delay: .2s;
}

@media (max-width: 1023px) {
    .inner {
        min-height: 76rem;
    }
}

.float {
    position: absolute;
    top: 9rem;
    left: 4rem;
    z-index: 2;

    @media (max-width: 767px) {
        left: .8rem;
    }
}

.aside {
    position: absolute;
    top: 0;
    left: calc(50% + (64rem / 100 * 30));
    bottom: 0;
    width: 100rem;
    animation: moveAnimation 1.5s alternate;
}

@keyframes moveAnimation {
    0% {
        transform: translateX(5rem);
    }
    100% {
        transform: translateX(0);
    }
}

.preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 0 50%;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
}

.cover {
    max-width: 100%;
    height: auto;
    display: block;
}

@media (max-width: 1280px) {

    .panel {
        width: 100%;
        text-align: center;
    }
    .aside {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30rem;
        width: 100%;
    }

    .preview {
        margin-bottom: -40%;
        align-items: end;
    }

    .cover {
        margin: 0 auto;
    }

    .inner {
        padding-bottom: 0;
        overflow: hidden;
    }

}

@media (max-width: 767px) {

    .aside {
        height: auto;
        margin: 2.5rem 0 -5rem;
    }

    .preview {
        margin-bottom: 0;
        position: relative;
    }

    .panel {
        text-align: left;
    }
}