.links {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    line-height: 1.2;

    a {
        color: #A4A3B1;
        transition: color .3s;

        &:hover {
            color: #F17467;
        }
    }

    @media (max-width: 1023px) {
        a {
            color: #F17467;

            &:hover {
                color: #A4A3B1;
            }
        }
    }
}

.line {
    + .line {
        margin-top: .8rem;
    }
}

.title {
    font-weight: 600;
    display: block;
    margin-bottom: .4rem;
    font-size: 1.8rem;
}