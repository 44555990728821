.burger {
    display: none;
    padding: 1.5rem 1rem;
    margin: 0 -1rem 0 0;
    background-color: transparent;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
        content:"";
        width: 1.8rem;
        height: 2px;
        background-color: #fff;
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        // transition: transform .3s ease-in-out;
    }

    &:before {
        top: .8rem;
    }

    &:after {
        bottom: .8rem;
    }

    &.open {

        @media (max-width: 1023px) {
            &:before {
                transform: rotate(45deg) translateX(0);
                left: 25%;
                top: 1.6rem;
            }

            &:after {
                transform: rotate(-45deg) translateX(0);
                left: 25%;
                bottom: 1.4rem;
            }

            .line {
                opacity: 0;
            }
        }
    }
}

.line {
    width: 1.8rem;
    height: 2px;
    background-color: #fff;
    display: block;
}

@media (max-width: 1023px) {
    .burger {
        display: block;
    }
}