.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
}

.cell {
    border-radius: .4rem;
    background-color: #fff;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: .8rem 1.2rem;
}