.content {
    width: 100%;
    max-width: 80rem;
    
    font-size: 1.8rem;
    line-height: 1.8;
    font-weight: 500;

    p {
        margin: 2.4rem 0;

        + ul {
            margin: 0 0 4rem;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    strong,
    b {
        font-weight: 700;
    }

    h5 {
        + ul {
            margin-top: .8rem;
        }

        + p {
            margin-top: .8rem;
        }
    }

    h4,
    h3,
    h2 {
        margin: 6.4rem 0 1.2rem;

        + p {
            margin-top: 1.2rem;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ol,
    ul {
        padding: 0;
        margin: 2.4rem 0;
        list-style: none;

        li {
            padding-left: 3rem;
            position: relative;
        }
    }

    ol {
        counter-reset: init-counter;

        li {
            counter-increment: init-counter;

            &:before {
                content: counter(init-counter) ". ";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                margin: 0rem 0 0 1rem;
            }
        }
    }

    ul {
        li {
            &:before {
                content:"";
                width: .4rem;
                height: .4rem;
                border-radius: 50%;
                background-color: #262728;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                margin: 1.4rem 0 0 1rem;
            }
        }
    }

    a {
        color: #d33810;

        &:hover {
            color: #5C5858;
        }

         &:active {
            color: #752A22;
        }
    }

    table {
        width: 100%;
        min-width: 70rem;
        background-color: #fff;
        border: .1rem solid #B8B7D3;
        box-shadow: 0 .8rem 4.8rem rgba(0, 0, 0, 0.12);
        border-spacing: 0;
        border-collapse: separate;
        border-radius: 1.2rem;

        td {
            padding: .8rem 2.4rem;
            vertical-align: top;
        }
    }

    thead {
        td {
            background-color: #fff;
            border-bottom: .1rem solid #B8B7D3;
            font-size: 1.8rem;
            font-weight: 600;

            &:first-child {
                border-radius: 1.2rem 0 0 0;
            }

            &:last-child {
                border-radius: 0 1.2rem 0 0;
            }
        }
    }

    tbody {
        tr {
            + tr {
                td {
                    border-top: .1rem solid #B8B7D3;
                }
            }
        }

        td {
            font-size: 1.4rem;
        }
    }
}