.layout {
  position: relative;
  display: flex;
  overflow: hidden;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}