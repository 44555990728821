.welcome {
    position: relative;
    background: linear-gradient(101.73deg, #4c5061 1.46%, #272a3a 92.4%), linear-gradient(106.92deg, rgba(0, 0, 0, .64) 9.21%, rgba(52, 82, 82, .64));

    &.show {
        .image {
            opacity: 1;
        }

        .title,
        .description {
            opacity: 1;
            transform: translateY(0);
        }
    }

}

.inner {
    position: relative;
    z-index: 2;
    height: 100%;
    min-height: 80rem;
    display: flex;
    padding-top: 16rem;
    padding-bottom: 8rem;
    align-items: center;
    color: #fff;
}

.media {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
}

.shadow {
    &:before {
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64));
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: 1;
    }
}

.image {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    animation: scaleAnimation 12s infinite;
    opacity: 0;
    transition: opacity 2s;
    transition-delay: .1s;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}

.panel {
    width: 100%;
    max-width: 90rem;
}

.description {
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 1.6rem;
    font-weight: 400;

    @media (max-width: 767px) {
        font-size: 1.8rem;
    }
}

.description,
.title {
    opacity: 0;
    transform: translateY(3rem);
    transition: opacity .8s, transform .4s;
}

.description {
    transition-delay: .2s;
}


@media (max-width: 1023px) {
    .inner {
        min-height: 76rem;
    }
}

@media (max-width: 767px) {
    .inner {
        height: 90vh;
        min-height: 60rem;
        padding-top: 10rem;
        padding-bottom: 4rem;
    }
}