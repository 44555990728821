.menu {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.item {
    display: flex;
    align-items: center;

    + .item {
        margin-left: 3.2rem;
    }
}

.link {
    display: block;
    color: #fff;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        height: 0.3rem;
        margin-bottom: -2.8rem;
        transition: opacity .3s;
        opacity: 0;
        background-color: #fff;
    }

    &:hover {
        &:before {
            opacity: 0;
        }
    }
}

.active {
    &:before {
        opacity: 1;
    }
}

@media (max-width: 1023px) {
    .menu {
        flex-wrap: wrap;
        padding-bottom: 4rem;
        margin-bottom: 2.4rem;
        border-bottom: 1px solid #676767;
    }

    .item {
        width: 100%;

        + .item {
            margin: .8rem 0 0 0;
        }
    }

    .link {
        font-size: 2rem;
        line-height: 1.4;
        padding: .8rem 0;

        &:before {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .menu {
        padding-bottom: 1.6rem;
    }
}