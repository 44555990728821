.section {
    padding: 16rem 0 8rem;
    flex: 1;
    background: linear-gradient(-106.92deg, #000000 9.21%, #345252 104.56%);
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.title {
    max-width: 50rem;   
}

.inner {
    position: relative;
    z-index: 2;
}

.footer {
    margin-top: 4rem;
}

.image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: url(../../assets/images/pages/404.svg) 50% 100% no-repeat;
    background-size: contain;
    width: 100rem;
    height: 40rem;
    margin: 0 -1.5rem -1.5rem 0;
}

@media (max-width: 1600px) {
    .image {
        width: 60vw;
    }
}

@media (max-width: 1023px) {
    .section {
        padding: 16rem 0 25.6rem;
    }
    .image {
        width: 57.5rem;
        height: 22rem;
        margin: 0 0 -1.5rem 0;
    }

    .footer {

        > a {
            width: inherit;
            display: inline-block;
        }
    }
}

@media (max-width: 767px) {
    .section {
        padding: 16rem 0 1.6rem;
    }

    .footer {
        margin-top: 22rem;
    }
    .image {
        width: 49rem;
        right: 50%;
        transform: translate(50%);
        height: 19rem;
        margin: 0;
    }
}