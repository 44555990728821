.section {
    background-color: #F4F4F4;
    padding: 8rem 0;
}

.list {

}

.line {
    padding-bottom: 4rem;
    @media (max-width: 1023px) {
        padding-bottom: 3.2rem;
    }
    + .line {
        border-top: 1px solid #E6E6E6;
        padding-top: 4rem;
        @media (max-width: 1023px) {
            padding-top: 3.2rem;
        }
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.content {
    max-width: 80rem;

    p {
        margin: 0;

        + p {
            margin-top: 1.6rem;
        }
    }
}

.title {
    margin-bottom: 1.6rem;
}

.grid {
    display: flex;
    grid-gap: 1.6rem;
}

@media (max-width: 1023px) {
    .section {
        padding: 6.4rem 0;
    }
}