.grid {
    display: flex;
    margin: 0 -2rem;

    @media (max-width: 1023px) {
        flex-wrap: wrap;
    }
}

.cell {
    width: 50%;
    padding: 0 2rem;

    @media (max-width: 1023px) {
        width: 100%;
    }
}

.title {
    + .description {
        margin-top: .8rem;
    }
}

.description {
    p {
        margin: 0;
    }
}