.card {
    color: #fff;
    padding: 4rem;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: .4rem;
    display: block;

    @media (max-width: 1023px) {
        padding: 2.4rem;
    }
}

.body {
    min-height: 32rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 55%;

    @media (max-width: 1023px) {
        width: 100%;
        min-height: inherit;
    }

    + .footer {
        margin-top: 2rem;
    }

    @media (max-width: 767px) {
        margin-top: 2.4rem;
    }
}


.description {
    margin-top: .4rem;
}

.media {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45%;
    overflow: hidden;

    @media (max-width: 1023px) {
        position: relative;
        width: auto;
        height: 40rem;
        margin: -2.4rem -2.4rem 0;
    }

    @media (max-width: 767px) {
        height: auto;
    }
}

.image {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    transform: translateX(10%);
    transition: transform .8s;

    min-width: 40rem;

    &:hover {
        @media (min-width: 1024px) {
            transform: translateX(2.5%);
        }
    }

    @media (max-width: 1023px) {
        transform: none;
        background-size: contain;
    }

    @media (max-width: 767px) {
        aspect-ratio: 680 / 400;
    }
}