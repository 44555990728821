@import-normalize;

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

:active,
:focus,
:hover {
    outline: 0;
}

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
}

#root {
    height: 100%;
}

html {
    font-size: 10px;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    min-width: 320px;
    color: #262728;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
}

a {
    transition: color .3s ease;
    text-decoration: none;
    color: #5c5858;

    &:hover {
        text-decoration: none;
        color: #d33810;
    }

    &:active {
        color: #752a22;
    }
}

p {
    margin: 1.6rem 0;
}

p:empty {
    display: none;
}

input {
    border-radius: 0;
    -webkit-appearance: none;
}

.container {
    width: 100%;
    max-width: 128rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: 4rem;
    padding-left: 4rem;
}

input,
textarea {
    font-family: 'Poppins', Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

::selection{
    color: #D33810;
    background-color: #ccc;
}


// Typography
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    font-weight: 600;
    display: block;
    margin: 0;
    line-height: 1.2;
}

h1,.h1 {
    font-size: 5.6rem;
}

h2,.h2 {
    font-size: 4.4rem;
}

h3,.h3 {
    font-size: 3.2rem;
}

h4,.h4 {
    font-size: 2.4rem;
}

h5,.h5 {
    font-size: 2rem;
}

@media (max-width: 1023px) {
    h1,.h1 {
        font-size: 3.2rem;
    }
    
    h2,.h2 {
        font-size: 3.2rem;
    }
    
    h3,.h3 {
        font-size: 2.8rem;
    }
    
    h6,.h6 {
        font-size: 1.8rem;
    }
}

@media (max-width: 767px) {
    .container {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }

    h2,.h2 {
        font-size: 2.8rem;
    }

    h4, .h4 {
        font-size: 2rem;
    }
}