.section {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
    background-color: #fff;

    @media (max-width: 767px) {
        padding-top: 4.8rem;
        padding-bottom: 4.8rem;
    }
}

.head {
    + .body {
        margin-top: 3.2rem;

        @media (max-width: 1023px) {
            margin-top: 2.4rem;
        }
    }
}

.primary {
    background-color: #F4F4F4;
}

.title {
    color: #262728;
    max-width: 80rem;
}

.description {
    max-width: 80rem;
    margin-top: .8rem;
}