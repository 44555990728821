.footer {
    background-color: #131313;
    color: #fff;
}

.navigation {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;

    @media (max-width: 1023px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    @media (max-width: 767px) {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }
}

.base {
    padding: 2.4rem 0;
    border-top: 1px solid #272727;

    a {
        color: #A4A3B1;

        transition: color .3s;

        &:hover {
            color: #F17467;
        }
    }

    @media (max-width: 767px) {
        text-align: center;
    }
}

.copyright {
    margin: 0;
    color: #5C5858;
    font-size: 1.4rem;
    line-height: 1.5;

    @media (max-width: 767px) {
        font-size: 1.6rem;
        margin-top: 3.2rem;
    }
} 

.grid {
    display: flex;
    flex-direction: row;
    margin: 0 -2rem;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
}

.logo {
    display: block;
    width: 20rem;
    height: 6rem;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background: url(../../assets/images/logos/xpgfactor-grey.svg) 50% 50% no-repeat;
    background-size: contain;
    transition: opacity .3s;

    @media (max-width: 1023px) {
        width: 9.4rem;
        height: 2.8rem;
    }

    @media (max-width: 1023px) {
        margin: 0 auto 2.6rem;
    }

    &:hover {
        opacity: .8;
    }
}

.cell {
    padding: 0 2rem;

    @media (max-width: 767px) {
        width: 100%;
        text-align: center;
    }
}

.links {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    line-height: 1.2;

    a {
        color: #A4A3B1;
        transition: color .3s;

        &:hover {
            color: #F17467;
        }
    }
}

.line {
    + .line {
        margin-top: .8rem;
    }
}

.title {
    font-weight: 600;
    display: block;
    margin-bottom: .4rem;
}