.card {
    padding: 2.4rem;
    border-radius: .4rem;
    border: 1px solid #e6e6e6;
    color: #fff;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.title {
    line-height: 1.4;
}

.description {
    margin-top: .8rem;
}

.head {
    + .body {
        margin-top: 2.4rem;
    }
}

.icon {
    width: 6.4rem;
    height: 6.4rem;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: .8;
}