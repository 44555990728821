.header {
    position: fixed;
    z-index: 15;
    top: 0;
    right: 0;
    left: 0;
    padding: 1.5rem 0;
    border-bottom: .1rem solid rgba(107, 107, 107, .4);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-20%, rgba(0, 0, 0, .56)), to(rgba(0, 0, 0, 0)));
    background: -o-linear-gradient(top, rgba(0, 0, 0, .56) -20%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, .56) -20%, rgba(0, 0, 0, 0) 100%);
    

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        transition: opacity 1s;
        opacity: 0;
        background-color: #272727;
    }
}

.up {
    &:before {
        opacity: 1;
    }
}

.inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panel {
    display: flex;
    align-items: center;
}

.aside {

}

.logo {
    display: block;
    width: 11.4rem;
    height: 3.4rem;
    margin-right: 8rem;
    transition: opacity .3s;
    background: url(../../assets/images/logos/xpgfactor-white.svg) 50% 50% no-repeat;
    background-size: contain;
    transition: opacity .3s;

    &:hover {
        opacity: .8;
    }
}

@media (max-width: 1023px) {
    .aside,
    .menu {
        display: none;
    }

    .logo {
        width: 8rem;
        height: 2.4rem;
    }
}