.grid {
    display: grid;
    grid-gap: 2.4rem;
}

.grid-2 {
    grid-template-columns: 1fr 1fr;
}

.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 1023px) {
    .grid-3 {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 767px) {
    .grid-2 {
        grid-gap: 1.6rem;
        grid-template-columns: 1fr;
    }
}