.grid {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1023px) {
        grid-template-columns: 1fr;
        grid-gap: 3.2rem;
    }
    @media (max-width: 767px) {
        grid-gap: 2.4rem;
    }
}

.content {
    margin-top: .8rem;
}