.panel {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100vh;
    padding: 6.4rem 0 0 0;
    transition: opacity .3s, transform .6s, visibility .1s, .1s;
    transform: translateY(-10vh);
    opacity: 0;
    color: #fff;
    background-color: #272727;

    &.open {

        @media (max-width: 1023px) {
            opacity: 1;
            z-index: 14;
            visibility: visible;
            transform: translateY(0);
        }
        
    }
}

.autoscroll {
    overflow-y: auto;
    width: 100%;
    height: 100%;

    > div {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}


.body,
.footer {
    padding: 2.6rem 0;
    width: 100%;
}

.footer {
    > button {
        width: 100%;
    }
}