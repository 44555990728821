.card {
    background-color: #F4F4F4;
    border-radius: .4rem;
    padding: 2.4rem;
}

.icon {
    width: 6.4rem;
    height: 6.4rem;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.head {
    + .body {
        margin-top: 2.4rem;
    }
}

.description {
    margin-top: .8rem;
}
