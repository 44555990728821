.progressbar {
    height: 1.6rem;
    border-radius: .8rem;
    width: 100%;
    background: linear-gradient(90deg, #D33810 35%, #EFE9E8 65%);
    background-size: 300% 100%;
    background-position: 70% 0;
    transition: background-position .8s;

    @media (max-width: 1023px) {
        background: rgba(169, 75, 65, 0.12);
    }
}

.step-1 {
    background-position: 70% 0;
}

.step-2 {
    background-position: 50% 0;
}

.step-3 {
    background-position: 0% 0;
}

.grid {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 1023px) {
        grid-template-columns: 1fr;
    }

    &:not(:hover) {

        @media (min-width: 1024px) {
        

            .box {
                &:first-child {

                    .title {
                        color: #89352C;
                    }

                    .point {
                        &:before {
                            width: 3.2rem;
                            height: 3.2rem;
                            background-color: #D33810;
                        }
                    }
                    
                    .description {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

        }
        
    }
}

.infographic {
    position: relative;
    @media (max-width: 1023px) {
        padding-left: 4rem;

        .progressbar {
            position: absolute;
            top: 0;
            left: 0;
            width: 1.6rem;
            height: auto;
            bottom: 0;
            margin-top: .8rem;
        }
    }
}


.title {
    color: #262728;
    font-size: 2.4rem;
    line-height: 1.25;
    position: relative;
    display: block;
    transition: color .3s;
    font-weight: 600;
}

.description {
    margin-top: .8rem;
    opacity: 0;
    transform: translateY(1rem);
    transition: transform .6s, opacity .8s;
    p {
        margin: 0;
    }
}

.box {
    position: relative;
    padding-top: 1.6rem;

    &:hover {

        @media (min-width: 1024px) {

            .title  {
                color: #89352C;
            }

            .point {
                &:before {
                    width: 3.2rem;
                    height: 3.2rem;
                    background-color: #D33810;
                }
            }

            .description {
                opacity: 1;
                transform: translateY(0);
            }
        }

    }

    @media (max-width: 1023px) {
        padding-top: 0;

        .point {
            &:before {
                width: 1.8rem;
                height: 1.8rem;
                background-color: #D33810;
            }
        }
        .description {
            opacity: 1;
            transform: translateY(0);
        }
    }


}

.point {
    width: 0px;
    height: 0px;
    position: absolute;
    top: 0;
    left: 0;
    margin: -.8rem 0 0 .8rem;
    z-index: 2;
    display: block;

    &:before {
        content:"";
        display: block;
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(169, 75, 65, 0.28);
        border: 2px solid #fff;
        transition: width .3s, height .3s, background-color .3s;
        cursor: pointer;
    }

    @media (max-width: 1023px) {
        margin: 1.4rem 0 0 -3.2rem;
    }
}