.form {
    position: relative;
}

.row {
    display: flex;
    margin: 0 -1.2rem;

    + .row {
        margin-top: 2.4rem;
    }
}

.cell {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    width: 100%;
}

.control {
    border: .1rem solid rgba(169, 75, 65, .28);
    padding: 1.4rem 1.6rem;
    font-size: 1.4rem;
    line-height: 1;
    background-color: #fff;
    border-radius: .4rem;
    width: 100%;
    vertical-align: top;
    font-weight: 400;
    transition: border .3s;

    &::placeholder {
        color: #5C5858;
        transition: color .3s;
    }

    &:focus {

        &::placeholder {
            color: transparent;
        }
    }
}

.error {
    border-color: #d33810;
}

textarea {
    &.control {
        height: 14rem;
        resize: none;
    }
}

.checkbox {

    a {
        color: #D33810;
        transition: color .3s;
        &:hover {
            color: #A4A3B1;
        }
    }
    
    label {
        display: block;
        padding-left: 4rem;
        position: relative;
    }

    input[type=checkbox] {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked {
            
            + span {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    span {
        display: block;

        &:before {
            content: "";
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 2.4rem;
            height: 2.4rem;
            background: url(../../assets/images/icons/checkout-default.svg) 50% 50% no-repeat;
            background-size: contain;
            cursor: pointer;
        }

        &:after {
            content:"";
            display: block;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 2.4rem;
            height: 2.4rem;
            background: url(../../assets/images/icons/checkout-active.svg) 50% 50% no-repeat;
            background-size: contain;
            opacity: 0;
            transition: opacity .3s;
            cursor: pointer;
        }
    }
}

@media (max-width: 1023px ) {

    .form {
        margin-top: 2.4rem;
    }

    .row {
        flex-direction: column;
    }
    
    .cell {
        + .cell {
            margin-top: 2.4rem;
        }
    }
}

.thanks {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background:#fff url(../../assets/images/pages/thanks.png) 50% 50% no-repeat;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 1s, visibility .1s .1s;

    &.show {
        opacity: 1;
        visibility: visible;
        z-index: 2;
    }
}